<template>
  <div>
    <!-- 横滑的标题 -->
    <div 
      v-if="title && showTitle"
      class="recommend-v2-slide__title"
    >
      <i></i>
      <span>{{ title.name }}</span>
      <i></i>
    </div>

    <template v-for="(rec, index) in tabProduct">
      <RecommendUI
        :ref="`RecUI${index}`"
        :key="index"
        :recommend-service="rec.dataInstance"
        :slider-recommend="true"
        :origin-config="originConfig"
        @recommendListReady="(payload) => onRecReady(payload, index)"
        v-on="$listeners"
      />
    </template>
  </div>
</template>

<script>
import RecommendUI from './index.vue'
import RecommendUIService from './index.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
const daEventExpose = daEventCenter.getExposeInstance()

export default {
  name: 'RecommendSliderGroup',
  components: {
    RecommendUI
  },
  inheritAttrs: false,
  props: {
    /**
     * 推荐服务的实例
     */
    recommendService: {
      require: true,
      type: Object,
      default() {
        return null
      }
    },
  },
  data() {
    return {
      showTitle: false,
      tabProduct: []
    }
  },
  computed: {
    title() {
      return this.recommendService?.title || null
    }
  },
  watch: {
    recommendService: {
      handler(v, o) {
        if (typeof window === 'undefined') return
        if (!o) this.init()
      },
      immediate: true  
    }
  },
  methods: {
    async init() {
      // 1. 获取tab的数据
      const { products: tabData }  = await this.recommendService.getTabData()
      if (!tabData.length) return
      // 2. 原先的配置
      const { config, product: initialProduct, analysis, tab: initialTab } = this.recommendService
      let tabProduct = []
      tabData.forEach((item, index) => {
        // 2.1 title
        const title = {
          name: item.item_name,
          config: {
            titleDaExposeEventId: '2-4-6',   // slide组的话要分板块曝光
          }
        }

        // 2.2 product
        const initProductConfig = initialProduct.config
        const product = JSON.parse(JSON.stringify(initialProduct))
        if (product.request && product.request.params) {
          const params = product.request.params
          // 更换了接口入参数变化
          if (product.request.atomic) {
            product.request.params.contextParams = {
              ...product.request.params.contextParams,
              cate_ids: item.sku_cate_id,
              goods_ids: '',
            }
          } else {
            product.request.params = {
              ...params,
              cateIds: item.sku_cate_id,
              goodsIds: ''
            }
          }
        }
        if (initProductConfig) { // config 会有设置函数的时候
          product.config = {
            ...initProductConfig,
            showViewMore: true
          }
        }

        // 2.3 如果商品有容错配置
        if (product.backupRequest) {
          product.backupRequest.params.cate_id = item.sku_cate_id
        }


        // 2.4 other
        const { ruleId, pageId, floor, comId } = config
        const spm = [ ruleId, pageId, floor, comId, index + 1 ].join('`')
        product.analysis = {
          ...product.analysis,
          // activeFrom: ['auto_rcmd_goods_list', spm].join('`'),
          activeFrom: 'auto_rcmd_goods_list',
          style: 'list',
          viewMoreItem: {
            itemDAEventClickId: '2-4-3',    // 声明式埋点点击id
          },
        }

        const REC_CONFIG = {
          title,
          config: {
            ...config,
            sku_cate_id: item.sku_cate_id,
            sku_cate_nm: item.sku_cate_nm,
            index,
          },
          product,
          analysis: {
            ...analysis,
            spm,
            tabList: `${index + 1}\`${item.sku_cate_id}\`${item.sku_cate_nm}`,
          }
        }
        tabProduct.push({
          originConfig: REC_CONFIG,
          dataInstance: new RecommendUIService(REC_CONFIG),
          analysis: initialTab.analysis || {}
        })

      })

      this.tabProduct = tabProduct
    },
    onRecReady(payload, index) {
      const recInstance = this.$refs[`RecUI${index}`]
      if (recInstance) {
        const el = recInstance[0].$el
        if (!el) return
        // 绑定当前块的曝光埋点
        this.$nextTick(() => {
          const key = `auto_rec_group${new Date().getTime()}`
          el.setAttribute('da-expose-code', key)
          daEventExpose && daEventExpose.subscribe({
            keycode: `${key}\`2-4-6`,
          })
        })
      }

      // 如果没有设置标题
      if (this.title.name) {
        this.showTitle = payload?.list?.length > 0
        this.$emit('showSliderGroupTitle', this.showTitle)
      }
    }
  },
}
</script>

<style lang="less" scoped>
.recommend-v2-slide__title {
  text-align: center;
  padding: 1.1733rem 0;
  background: #f6f6f6;
  span {
    vertical-align: middle;
    display: inline-block;
    max-width: 6.2667rem;
    line-height: 0.5333rem;
    margin: 0 0.4rem;
    font-weight: bold;
    .font-dpr(32px);
    // text-transform: uppercase;

    word-wrap:break-word;
    // word-break:break-all;
  }
  i {
    vertical-align: top;
    .rhomb();
    width: 6px; // 设计要求写死
    height: 6px; // 设计要求写死
    margin-top: .17rem;
  }
}
</style>
