<template>
  <div>
    <!-- 推荐位间隔 -->
    <div 
      v-if="showRecommendGap"
      class="recommend-gap" 
    >
      <span></span>
      <span class="block"></span>
      <span></span>
    </div>
    <template v-if="recommendUiGroup.length">
      <template v-for="(rec, index) in recommendUiGroup">
        <RankListEnter
          v-if="rec.comKey == 'RANK_LIST_RCMD'"
          :key="index"
          :code="code"
          :config="rec.config"
          :analysis-info="rec.analysisInfo"
          :auto-do-recommend="true"
        />
        <template v-else>
          <SliderGroup
            v-if="rec.sliderRecommend"
            :key="index"
            :recommend-service="rec.dataInstance"
            v-on="$listeners"
          />
          <RecommendUI
            v-else
            :key="index"
            :recommend-service="rec.dataInstance"
            :origin-config="rec.originConfig"
            v-on="$listeners"
          />
        </template>
      </template>
    </template>
    <!-- 占位 -->
    <RecommendUI
      v-else-if="showHolder && innderHolderConfig"
      :origin-config="innderHolderConfig"
    />
  </div>
</template>

<script>
import SliderGroup from './sliderGroup.vue'
import RecommendUI from './index.vue'
import RecommendUIService, { CccRecommendUIService } from './index.js'
import { getRecAbtResult, getRecMaterialConfig } from './utils'
const { IS_RW = false } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

import { daEventCenter } from 'public/src/services/eventCenter/index'
daEventCenter.addSubscriber({ modulecode: '2-4' })

const defaultConfig = {
  GOODS_2: {
    rowNum: 2,
    loadConfig: {
      limit: 40,
      reqNum: 100,
    }
  },
  GOODS_3: {
    rowNum: 3,
    loadConfig: {
      limit: 60,
      reqNum: 100,
    }
  },
  TAB_GOODS_2: {
    rowNum: 2,
    loadConfig: {
      limit: 40,
      reqNum: 100,
    },
    goodsCollection: {
      limitFiled: 'tabQuantity',
      sceneField: 'tabId'
    }
  },
  TAB_GOODS_3: {
    rowNum: 3,
    loadConfig: {
      limit: 60,
      reqNum: 100,
    },
    goodsCollection: {
      limitFiled: 'tabQuantity',
      sceneField: 'tabId'
    }
  },
  SLIDE_GOODS_1: {
    rowNum: 3,
    loadConfig: {
      limit: 10,
      reqNum: 10
    },
    goodsCollection: {
      limitFiled: 'productGroupQuantity',
      sceneField: 'groupId'
    }
  },
}

export default {
  name: 'CccAutoRecommendGroup',
  components: {
    RankListEnter: () => import('public/src/services/productRecommend/components/rankListEnter.vue'),
    RecommendUI,
    SliderGroup
  },
  inheritAttrs: false,
  props: {
    showRecommendGap: {
      type: Boolean,
      default: false
    },
    code: {
      type: String,
      default: ''
    },

    poskey: {
      type: String,
      default: ''
    },

    /**
     * show 是否展示
     * rowNum 展示占位多少个
     */
    holderConfig: {
      type: Object,
      default() {
        return {}
      }
    },

    /**
     * @param {Number} stickyTop tab类型在滚动吸顶距离
     */
    stickyTop: {
      type: Number,
      default: 0
    },

    /** 
     * @param {Object} config 配置
     *  @param {Object} sceneConfig 对应的场景特殊配置
     *  @param {Object} title 对应的标题的配置
     *  @param {Object} product 对应的商品的配置
     *  @param {Object} tab 对应的tab的配置
     *    @param {Function} tabClickPreChange
     *    @param {Function} tabClickDone
     */
    config: {
      type: Object,
      default () {
        return {}
      }
    },

    /** 
     * @param {Object} cccConfig ccc的配置
     *  @param {Object} data 如果有默认请求好的，传入固定的数据ccc的配置
     *  @param {Object} params ccc请求的参数，如果有data，则不需要params
     */
    cccConfig: {
      type: Object,
      require: true,
      validator(params) {
        // 1. 没有参数取默认
        if (!params) return console.warn('[recommend-ccc-group]', 'error props: requestConfig is require')
        if (!params.data && !params.params) return console.warn('[recommend-ccc-group]', 'error props requestConfig: data or params have to have one')
        // 2. 有参数
        const bol = Object.prototype.toString(params) === '[object Object]'
        if (!bol) {
          return console.warn('[recommend-ccc-group]', 'error props: requestConfig should be a Object')
        }
        return bol
      },
      default() {
        return {}
      }
    },
    /**
     * 推荐请求扩展的参数
     */
    pdeExtendParams: {
      type: Object,
      default () {
        return {
          goodsList: ''
        }
      }
    },
    /** 
     * @param {Object} analysisInfo 埋点信息配置
     *   @param {Object} product
    */
    analysisInfo: {
      type: Object,
      default () {
        return {}
      }
    },
    channelId: {
      type: String,
      default: ''
    },
    /**
     * 强制分页加载
     */
    forceViewMore: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      abtResultValue: null,
      itemAbtCon: {},
      abtInfo: null,
      recommendUiGroup: [],
      CccRecommend: null,
      itemConfig: {
        code: 'recommend',
        moduleFrom: 'recommend',
        itemDAEventClickId: '2-4-1',    // 声明式埋点点击id
        itemDAEventExposeId: '2-4-2',   // 声明式埋点曝光id
        isCccRecommend: true,
        sa: {
          activity_from: 'auto_rcmd_goods_list',
        }
      },
      showHolder: true,
      innderHolderConfig: {
        product: {
          rowNum: 3
        }
      }
    }
  },
  created() {
    const { show = true, rowNum = 3 } = this.holderConfig || {}
    this.showHolder = show
    this.innderHolderConfig.product.rowNum = rowNum
  },
  async mounted() {
    if (!this.poskey) return this.emitHide()
    // 1. abt信息
    const { abtResult, ...arg } = await this.abtResult()
    this.abtResultValue = abtResult
    this.itemAbtCon = {
      ...arg
    }
    this.abtInfo = abtResult[this.poskey]
    if (!this.abtInfo?.param || this.abtInfo?.param === 'none') return this.emitHide()
    // 2. 服务实例
    this.CccRecommend = new CccRecommendUIService({ abtInfo: this.abtInfo, channelId: this.channelId })
    // 3. 初始化
    if(this.cccConfig && Object.keys(this.cccConfig) && Object.values(this.cccConfig).length > 0) {
      if (this.cccConfig.data) return this.init(this.cccConfig.data)

      const { onetwoTitleParams } = this.itemAbtCon
      const [cccRecConfig, { goodsNameLine, addBagBtn }] = await Promise.all([
        this.CccRecommend.getCccConfig({
          params: JSON.stringify(this.cccConfig.params || {})
        }),
        // 橱窗配置
        this.config.sceneConfig?.material?.length ? getRecMaterialConfig({ 
          pageKey: this.config.sceneConfig?.pageKey,
          material: this.config.sceneConfig?.material?.map(_ => {
            if (_.materialDataType == 'GoodsName') {
              return {
                ..._,
                abtBranch: onetwoTitleParams
              }
            }
            return _
          }),
          displayWindowAbtBranch: this.config.sceneConfig?.displayWindowAbtBranch,
        }) : {}
      ])
      this.init(cccRecConfig, { goodsNameLine, addBagBtn })
    } 
  },
  methods: {
    emitHide () {
      this.showHolder = false
      this.$emit('recommendListReady', { list: [], })
    },
    getRankingEntryConfig(cccRecConfig, cccItem, index) {
      const { recSwitch, ProductDetailBelowPolicyFloorJson } = this.abtResultValue
      const recSwitch50 = !!recSwitch?.p?.recSwitch?.split('|')?.includes('50')

      let content = cccItem.content && cccItem.content.props.items && cccItem.content.props.items[0]
      if (!content) return null
      const goodsRuleId = this.CccRecommend.getRuleId(index, 50) || ''
      const recommendConfig  = {
        type: 'request',
        method: 'POST',
        url: '/api/recommend/facadeAtom/get',
        notFaultTolerance: true,
        params: {
          scene_id: 50,
          // rule_id: goodsRuleId,
          pageEnable: content.entryId == 51 || content.entryId == 52 || Number(content.id) === 65 ? 0 : 1,
          contextParams: {
            ...this.pdeExtendParams || {}
          },
        }
      }
      if (recSwitch50) {
        recommendConfig.params.rule_id = goodsRuleId
      } else {
        recommendConfig.params.jsonRuleId = JSON.stringify(ProductDetailBelowPolicyFloorJson?.p)
        recommendConfig.params.location = 'ProductDetailBelowPolicyFloorJson'
      }
      const { ruleId, pageId } = cccRecConfig
      // spm格式：模板ID`页面ID`楼层ID`组件ID`组件坑位
      const spm = [ruleId, pageId, cccItem.floor, cccItem.comId, index + 1].join('`')
      return {
        comKey: 'RANK_LIST_RCMD',
        config: {
          paramsMatchConfig: recommendConfig,
        },
        analysisInfo: {
          rank_sceneId: 50,
          rank_ruleId: goodsRuleId,
          floor: cccItem.floor, 
          posKey: this.poskey, 
          cccConfig: cccRecConfig, 
          comId: cccItem.comId, 
          spm,
        }
      }
    },
    async init(cccRecConfig, extendsConfig = {}) {
      if (!cccRecConfig || (cccRecConfig && Object.keys(cccRecConfig) && Object.values(cccRecConfig).length < 1)) return this.emitHide()

      let recommendConfig = []
      const { goodsNameLine, addBagBtn } = extendsConfig
      const { showSellingPoint, showFeedback, showQuickShip, showNewDiscountLabel, showGreySellingPoint, showAddBagAfterGoodsPrice, canIChangeColorStyle, waterFallStyle } = this.itemAbtCon
      cccRecConfig.content.forEach((item, index) => {
        if (item.comKey == 'RANK_LIST_RCMD') {
          const rankingConfig = this.getRankingEntryConfig(cccRecConfig, item, index)
          rankingConfig ? recommendConfig.push(rankingConfig) : this.emitHide()
          return
        }
        /**
         * recoveryType
         *  RECOVERY_API
         *  HIDDEN (HIDDEN, 无数据不展示，表示不走容错)
         */
        const recoveryType = item.recoveryType || 'HIDDEN'
        const canUseBackup = recoveryType != 'HIDDEN'           // 是否通过容错来请求数据

        const cccStyle = item.content && item.content.props.style
        let content = item.content && item.content.props.items && item.content.props.items[0]
        // 1. 空数据
        if(!content) return
        // 针对组件不连续，则取手动插入的index
        let ruleIndex = item.cccIndex && this.abtInfo?.param?.indexOf('emarsys') < 0 ? item.cccIndex : index
        const goodsRuleId = this.CccRecommend.getRuleId(ruleIndex, content.id) || ''
        const isEmaRec = goodsRuleId.includes('emarsys')
        const isMinGoodsList = ['GOODS_3', 'TAB_GOODS_3', 'SLIDE_GOODS_1'].includes(item.comKey)
        const isMinSlideRec = item.comKey == 'SLIDE_GOODS_1'

        // 2. 商品的请求配置
        const product = {
          request: Object.assign(isEmaRec ? {
            type: 'emarsys',
            params: goodsRuleId,
          } : {
            type: 'request',
            method: 'POST',
            url: '/api/recommend/facadeAtom/get',
            atomic: true, // 商品接口才需要
            params: {
              pageEnable: content.entryId == 51 || content.entryId == 52 || Number(content.id) === 65 ? 0 : 1,
              rule_id: goodsRuleId,
              scene_id: content.id,
              contextParams: {
                ...this.pdeExtendParams || {}
              },
            },
          }, {
            query: {
              limit: 10,
              reqNum: 100,
            },
          }),
          backupRequest: canUseBackup ? {
            type: 'request',
            url: '/api/recommend/faultTolerantFacade/get',
            params: {
              rec_type: 1, // 推荐类型；1：商品  4：类目
              good_ids: this.pdeExtendParams && this.pdeExtendParams.goods_ids,  // 需要过滤的goodsId, 多个id逗号分割
              cate_id: this.pdeExtendParams && this.pdeExtendParams.cate_ids,
              pagename: SaPageInfo && SaPageInfo.page_name,
            },
            triggerLimit: 1, // 当结果少于多少触发容错
            timeout: 30000,
            sourceAbtInfo: this.abtInfo
          } : null,
          rowNum: defaultConfig[item.comKey]?.rowNum || 2,
        }

        const { product: productConfig = {} } = this.config
        const { items = {}, ...arg } = productConfig
        const showRowNum = product.rowNum
        const showMultiColor = content.showColor !== undefined ? content.showColor : true // colors
        const itemShowMultiColor = !isMinGoodsList && showMultiColor

        const itemTitleShow = typeof goodsNameLine !== 'undefined' ? {
          showTitle: !!Number(goodsNameLine),
          showTitleTwoLine: Number(goodsNameLine) === 2,
        } : {}
        const canIWaterFallStyle = showRowNum === 2 && waterFallStyle
        // 2.1 商品的配置
        product.config = {
          // 对应item项的配置
          items: {
            configReady: true,
            // 商品角标相关
            showSeriesBrand: true,          // 品牌系列
            showBadge: true,                // 各种ccc角标
            showPromoLabel: true,           // 大促角标标签
            hideDiscountBadge: true,        // 隐藏折扣角标
            showNewDiscountLabel,
            showGreySellingPoint,

            autoCropImage: canIWaterFallStyle, // 瀑布流样式下，自动裁剪图片
            // 分人群专区闪购
            flashZonePromotion: true, // 默认展示，如果不展示需要在业务组件中自行设置为 false
            // 标签相关
            showLocalSeller: !isMinSlideRec,                                   // 本地标签角标
            showSellingPoint: !isMinSlideRec && showSellingPoint,               // 卖点角标
            showQuickShip: !isMinSlideRec && showQuickShip,                  // 快速发货角标
            showSheinClubDiscountValue: !isMinSlideRec && !IS_RW,         // 默认展示付费会员折扣价 图标
            showPromotion: !isMinSlideRec, // 促销信息
            showLeftStock: !isMinSlideRec && (typeof content.showInStock !== 'undefined' ? content.showInStock : true), // 即将售罄
            showNewFlashLabel: content.showPrice && showRowNum > 2,     // 新型闪购折扣标签
            showNewFlashPrice: content.showPrice && showRowNum === 2,
            showNewFlashPriceOnSale: content.showPrice && showRowNum > 2,
            showAddBagAfterGoodsPrice: showRowNum === 2 ? showAddBagAfterGoodsPrice : false,
            showBeltLabel: true, // 一行两图展示腰带
            showEstimatedPriceReachThreshold: true,  // v2 到手价标签
            showEstimatedPriceOnSale: true, // v3 到手价标签
            priceDiscountDesc: showRowNum === 2, // 历史低价、保留款跟价款标签
            addBagBtn, // v3 加车按钮
            // 功能展示相关
            showDiscountLabelAfterPrice: true, // 折扣价标展示在价格右边
            showMultiColorIcon: isMinGoodsList && showMultiColor,
            showMultiColor: itemShowMultiColor,
            showPlusSize: !(['GOODS_2', 'TAB_GOODS_2'].includes(item.comKey) && content.showPlusSize !== undefined ? !content.showPlusSize : !['GOODS_2', 'TAB_GOODS_2'].includes(item.comKey)), // 不展示大尺码
            showAddBagBtn: content.shoppingCart,         // shoppingCart
            showFindSimilarBtn: content.findSimilar,      // findSimilar
            showLikeBtn: content.collect,             // collect 收藏
            showFeedback,
            colorSetPresentedType: itemShowMultiColor && canIChangeColorStyle ? 'ProColorB' : '', // 展示多色块，且abt控制需要转成竖排的样式的时候
            disableMainimgJump: ['SLIDE_GOODS_1'].includes(item.comKey),  // 点击商品是否跳转
            // hideRetailPrice: isMinGoodsList,
            hideRetailPrice: true,      // 隐藏划线价格【折扣标签展示在价格旁边需求】
            hideNormalPrices: !content.showPrice,         // price 是否展示价格
            ...itemTitleShow,
            ...this.itemConfig,
            ...items,
            // 价格相关
            
          },
          // 对应商品推荐列表的配置
          showViewMore: this.forceViewMore || content.viewMore || false,
          viewMorePreLoad: this.forceViewMore || content.viewMore && Number(cccStyle.viewMorePosition || 0) > 1,  // 如果有view more 是否需要预加载
          viewMorePosition: cccStyle.viewMorePosition || 0,
          waterFallStyle: canIWaterFallStyle, // 瀑布流样式
          ...arg
        }

        // 2.2 商品请求的配置
        // loadConfig reqNum(总数) 所有的请求类型必须设置
        let limit = ['SLIDE_GOODS_1'].includes(item.comKey) && Number(content.productQuantity) || defaultConfig[item.comKey].loadConfig.limit
        if (!['SLIDE_GOODS_1'].includes(item.comKey) && !content.viewMore) limit = content.limitNum

        product.request && (product.request.query = {
          ...defaultConfig[item.comKey].loadConfig || {},
          limit: this.forceViewMore ? 20 : limit,
          reqNum: ['SLIDE_GOODS_1'].includes(item.comKey) ? limit : (Number(content.limitNum) || 100)
        })

        // 3. title
        let title = {
          name: content.mainTitle,
          subName: content.subTitle,
          config: {
            titlePosition: content.titlePosition,
            ...(this.config?.title || {})
          },
        }

        // 4. tab
        let tab = null
        if (['TAB_GOODS_2', 'TAB_GOODS_3', 'SLIDE_GOODS_1'].includes(item.comKey)) {
          const { tab: tabConfig = {} } = this.config
          let sceneId = content[defaultConfig[item.comKey].goodsCollection.sceneField]
          let limit = content[defaultConfig[item.comKey].goodsCollection.limitFiled]
          tab = {
            request: {
              type: 'request',
              method: 'POST',
              url: '/api/recommend/facadeAtom/get',
              params: {
                scene_id: sceneId,
                rule_id: this.CccRecommend.getRuleId(ruleIndex, sceneId),
                contextParams: {
                  ...this.pdeExtendParams || {}
                },
              },
              query: {
                limit,
                reqNum: limit || 100,
              },
            },
            backupRequest: canUseBackup ? {
              type: 'request',
              url: '/api/recommend/faultTolerantFacade/get',
              params: {
                rec_type: 4, // 推荐类型；1：商品  4：类目
                scene_id: sceneId,  // 场景id，如果rec_type为4，则必填
                limit: limit,
                req_num: limit || 100,
                pagename: SaPageInfo && SaPageInfo.page_name,
              },
              triggerLimit: 1, // 触发走容错的时机
              timeout: 30000,
              sourceAbtInfo: this.abtInfo
            } : null,
            config: {
              stickyTop: this.stickyTop,
              ...tabConfig,
            },
            analysis: {
              tabDAClickEventId: '2-4-4',
              tabDAExposeEventId: '2-4-5',
            }
          }
        }


        // 5. other
        const { useFrom = undefined, atLast = false, ruleId, pageId } = cccRecConfig
        const config = {
          code: this.code,
          sceneConfig: this.config?.sceneConfig,
          cccRecommend: true,
          poskey: [this.poskey, 'imageLabel', 'PromotionalBelt'].join(),
          ruleId,
          pageId,
          floor: item.floor,
          comId: item.comId,
          tplActivityName: content.tplActivityName,   // 专题标识符
        }

        // 坑位id
        const pitId = useFrom && useFrom == 'index' && atLast ? '-1' : index + 1
        // spm格式：模板ID`页面ID`楼层ID`组件ID`组件坑位
        const spm = [ruleId, pageId, item.floor, item.comId, pitId].join('`')
        const analysis = {
          spm,
          position: pitId,
        }
        product.analysis = {
          sceneId: content.id,
          // auto_rcmd_goods_list`模板ID`页面ID`楼层ID`组件ID`组件坑位
          // activeFrom: ['auto_rcmd_goods_list', spm].join('`'),
          activeFrom: 'auto_rcmd_goods_list',
          viewMoreItem: content.viewMore ? {
            itemDAEventClickId: '2-4-3',    // 声明式埋点点击id
            errorDAEventExposeId: '2-4-9',   // 声明式埋点点击id
          } : null,
          ...(this.analysisInfo?.product || {})
        }
        // 6.
        const REC_CONFIG = {
          config,
          title,
          tab,
          product,
          analysis
        }
        const recServiceInstance = new RecommendUIService(REC_CONFIG)

        recommendConfig.push({
          originConfig: REC_CONFIG,
          dataInstance: recServiceInstance,
          sliderRecommend: ['SLIDE_GOODS_1'].includes(item.comKey),  // 是否是横滑推荐组件
        })
      })

      this.recommendUiGroup = recommendConfig

    },
    async abtResult() {
      const poskey = this.poskey
      return await getRecAbtResult({
        poskeys: poskey,
        newPoskeys: 'recSwitch,ProductDetailBelowPolicyFloorJson',
        showFeedbackPoskey: this.config?.product?.items?.showFeedbackPoskey,
        itemColorStylePoskey: this.config.sceneConfig?.itemColorStylePoskey || '',
      })
    }
  },
}
</script>
<style lang="less" scoped>
// 推荐组件的分隔线
.recommend-gap {
  height: 1.6rem;
  line-height: 1.6rem;
  text-align: center;
  background-color: #f6f6f6;
  span {
    display: inline-block;
    height: 1px;
    width: 60px;
    background-color: #ccc;
    vertical-align: middle;
  }
  .block {
    margin: 0 0.6133rem;
    .rhomb()
  }
}
</style>
